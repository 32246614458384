import Vuex from 'vuex';
import axios from 'axios';

const store = new Vuex.Store({
    state() {
        return {
            people: null,
            filteredPeople: null,
            filter: null,
        }
    },
    actions: {
        initializeState({ commit }) {
            return axios
                .get('https://run.mocky.io/v3/70e5b0ad-7112-41c5-853e-b382a39e65b7')
                .then(response => {
                    if (response.data.people) {
                        commit('setPeople', response.data.people);
                    }
                });
        },
        filterPeople({ commit, getters }, filter) {
            let filteredPeople = getters.filterPeople(filter);
            commit('setFilter', filter);
            commit('setFilteredPeople', filteredPeople);
        },
        filterPeopleOnClick({ commit, getters }, filter) {
            let filteredPeople = getters.filterPeopleByOneOption(filter);
            commit('setFilteredPeople', filteredPeople);
        },
        updatePerson({ commit, dispatch, state }, person) {
            commit('setPerson', person);
            if (state.filter) {
                dispatch('filterPeople', state.filter);
            }
        }
    },
    mutations: {
        setPeople(state, people) {
            state.people = people;
        },
        setFilteredPeople(state, filteredPeople) {
            state.filteredPeople = filteredPeople;
        },
        setFilter(state, filter) {
            state.filter = filter;
        },
        setPerson(state, person) {
            const index = state.people.findIndex((el) => el.id === person.id);
            state.people[index] = person;
        }
    },
    getters : {
        getPeople(state) {
            return state.filteredPeople || state.people;
        },
        getDataForChart(state, getters) {
            return (firstLevel, secondLevel = null) => {
                let elements = [];

                getters.getPeople.forEach(person => {
                    // Get current value
                    let current = secondLevel ? person[firstLevel][secondLevel] : person[firstLevel];

                    // Initialize array with first element
                    if (elements.length === 0) {
                        elements.push({
                            name: current,
                            y: 1
                        });
                    } else {
                        let exists = false;
                        // Check element already exists
                        for (let i = 0; i < elements.length; i++) {
                            if (elements[i]['name'] === current) {
                                elements[i]['y']++;
                                exists = true;
                                break;
                            }
                        }

                        // Create element into array if not exist
                        if (! exists) {
                            elements.push({
                                name: current,
                                y: 1
                            });
                        }
                    }
                });

                return elements;
            }
        },
        getDataForFilter(state) {
            return (firstLevel, secondLevel = null) => {
                let values = [];

                state.people.forEach(person => {
                    let currentValue = secondLevel ? person[firstLevel][secondLevel] : person[firstLevel];

                    if (values.length === 0) {
                        values.push(currentValue);
                    } else {
                        let exists = false;

                        values.every(value => {
                            if (currentValue === value) {
                                exists = true;
                                return false;
                            }
                            return true;
                        });

                        if (! exists) {
                            values.push(currentValue);
                        }
                    }
                });

                return values;
            }
        },
        filterPeople(state) {
            return (filter) => {
                return state.people.filter(item => {
                    return item.firstname.toUpperCase().includes(filter.firstname.toUpperCase())
                        && item.lastname.toUpperCase().includes(filter.lastname.toUpperCase())
                        && (filter.gender.length === 0 || filter.gender.indexOf(item.gender) !== -1)
                        && item.contact.city.toUpperCase().includes(filter.city.toUpperCase())
                        && item.contact.country.toUpperCase().includes(filter.country.toUpperCase())
                        && (filter.fruits.length === 0 || filter.fruits.indexOf(item.preferences.favorite_fruit) !== -1)
                        && (filter.colors.length === 0 || filter.colors.indexOf(item.preferences.favorite_color) !== -1)
                        && (filter.pets.length === 0 || filter.pets.indexOf(item.preferences.favorite_pet) !== -1);
                });
            }
        },
        filterPeopleByOneOption(state) {
            return (filter) => {
                const levels = filter.levels.split('-');
                return state.people.filter(item => {
                    return (levels[1] ? item[levels[0]][levels[1]] : item[levels[0]]).toUpperCase().includes(filter.value.toUpperCase());
                });
            }
        },
        simulateFilterPeople(state, getters) {
            return (filter) => {
                return getters.filterPeople(filter).length;
            }
        }
    }
});

export default store;
