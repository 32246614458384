<template>
    <div class="modal" :class="{ 'active': show }">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Filtres <span v-if="count">| {{ count }} résultat(s) !</span></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group row">
                            <label for="firstname" class="col-sm-2 col-form-label">Prénom</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="firstname" name="firstname" v-model="filter.firstname">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="lastname" class="col-sm-2 col-form-label">Nom</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="lastname" name="lastname" v-model="filter.lastname">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Sexe</label>
                            <div class="col-sm-10">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="checkbox" class="form-check-input" name="gender" value="Male" v-model="filter.gender">
                                        Homme
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input type="checkbox" class="form-check-input" name="gender" value="Female" v-model="filter.gender">
                                        Femme
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="city" class="col-sm-2 col-form-label">Ville</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="city" name="city" v-model="filter.city">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="country" class="col-sm-2 col-form-label">Pays</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="country" name="country" v-model="filter.country">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="form-group col-sm-4" v-if="fruits">
                                <label for="fruit">Fruit(s)</label>
                                <select multiple="" class="form-control" id="fruit" v-model="filter.fruits">
                                    <option v-for="fruit in fruits" :key="fruit">{{ fruit }}</option>
                                </select>
                            </div>
                            <div class="form-group col-sm-4" v-if="colors">
                                <label for="color">Couleur(s)</label>
                                <select multiple="" class="form-control" id="color" v-model="filter.colors">
                                    <option v-for="color in colors" :key="color">{{ color }}</option>
                                </select>
                            </div>
                            <div class="form-group col-sm-4" v-if="pets">
                                <label for="pet">Animal(Animaux)</label>
                                <select multiple="" class="form-control" id="pet" v-model="filter.pets">
                                    <option v-for="pet in pets" :key="pet">{{ pet }}</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" v-on:click="resetFilter">Réinitialiser le filtre</button>
                    <button type="button" class="btn btn-primary" v-on:click="applyFilter">Appliquer le filtre</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="closeModal">Quitter</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalFilter',
    emits: ['reloadView'],
    data() {
        return {
            show: false,
            colors: null,
            fruits: null,
            pets: null,
            filter: {
                firstname: '',
                lastname: '',
                gender: [],
                city: '',
                country: '',
                fruits: [],
                colors: [],
                pets: []
            },
            count: null
        }
    },
    methods: {
        openModal() {
            this.show = true;
            this.colors = this.$store.getters.getDataForFilter('preferences', 'favorite_color');
            this.fruits = this.$store.getters.getDataForFilter('preferences', 'favorite_fruit');
            this.pets = this.$store.getters.getDataForFilter('preferences', 'favorite_pet');
        },
        closeModal() {
            this.show = false;
        },
        resetFilter() {
            // Reset all inputs
            this.filter = {
                firstname: '',
                lastname: '',
                gender: [],
                city: '',
                country: '',
                fruits: [],
                colors: [],
                pets: []
            };
            // Reset filtered people in store
            this.$store.dispatch('filterPeople', this.filter);
            // Emit event to parent (navbar) to reload current view
            this.$emit('reloadView');
        },
        applyFilter() {
            this.$store.dispatch('filterPeople', this.filter);
            // Emit event to parent (navbar) to reload current view
            this.$emit('reloadView');
            this.closeModal();
        }
    },
    watch: {
        filter: {
            handler(newFilter) {
                this.count = this.$store.getters.simulateFilterPeople(newFilter);
            },
            deep: true
        }
    }
}
</script>

<style>
    div.modal.active {
        display: block;
    }
    div.form-group:last-child {
        margin-bottom: 0 !important;
    }
</style>
