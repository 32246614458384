<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <router-link :to="{ name: 'table' }" class="navbar-brand">Data Visualizer</router-link>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarColor01">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
                <router-link :to="{ name: 'table'}" class="nav-link">Tableau</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'pie-charts' }" class="nav-link">Camemberts</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'histograms' }" class="nav-link">Histogrammes</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'map' }" class="nav-link">Carte</router-link>
            </li>
        </ul>
        <ul class="navbar-nav">
            <li>
                <a v-on:click="displayModalFilter">
                    Filtres
                </a>
            </li>
        </ul>
    </div>
    </nav>

    <modal-filter ref="modalFilter" v-on:reloadView="reloadView" />
</template>

<script>
import ModalFilter from './modals/ModalFilter';

export default {
    name: 'Navbar',
    emits: ['reloadView'],
    components: {
        ModalFilter
    },
    methods: {
        displayModalFilter() {
            this.$refs.modalFilter.openModal();
        },
        reloadView() {
            // Emit event to parent (app) to reload current view
            this.$emit('reloadView');
        }
    }
}
</script>

<style>

</style>
