import { createWebHistory, createRouter } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'table',
        component: () => import('../views/Table')
    },
    {
        path: '/pie-charts',
        name: 'pie-charts',
        component: () => import('../views/PieChartGlobal')
    },
    {
        path: '/histograms',
        name: 'histograms',
        component: () => import('../views/HistogramGlobal')
    },
    {
        path: '/map',
        name: 'map',
        component: () => import('../views/Map')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
