<template>
    <navbar v-on:reloadView="reloadView" />
    <router-view v-if="loading" :key="viewKey" v-on:reloadView="reloadView"/>
</template>

<script>
import Navbar from './components/Navbar.vue';

export default {
    name: 'App',
    components: {
        Navbar
    },
    data() {
        return {
            loading: false,
            viewKey: 0,
        }
    },
    created() {
        this.$store.dispatch('initializeState')
            .then(
                () => {
                    this.loading = true;
                }
            );
    },
    methods: {
        reloadView() {
            this.viewKey++;
            // More explication : https://michaelnthiessen.com/force-re-render/
        }
    }
}
</script>

<style lang="scss">
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}
</style>
